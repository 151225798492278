<template>
  <div>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <div class="card-placeholder">
          <div class="card-body mt-2 ml-2">
            <filter-regional v-model:value="regionals" />
          </div>
          <div class="card-body mt-2 ml-2">
             <filter-kabupaten-kota  v-model:value="kabupaten" v-model:area="areas" />
          </div>
          <div class="card-body mt-2 ml-2">
            <filter-distributor v-model:value="distributor" v-model:distributor="distributors"/>
          </div>
          <div class="card-body mt-2 ml-2">
            <filter-brands v-model:value="brands" v-model:brand="brands" />
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-12">
        <div class="card-placeholder">
          <div class="card-body mt-2 ml-2">
            <filter-province v-model:value="province" v-model:regional="regionals" />
          </div>
          <div class="card-body mt-2 ml-2">
             <a-date-picker v-model:value="value2" :format="dateFormatList" placeholder="Pilih Tanggal Mulai" />
          </div>
          <div class="card-body ml-2 mt-2">
            <a-select label-in-value v-model:value="value" style="width: 300px" @change="handleChange" placeholder="Pilih Toko">
            <a-select-option value="toko_081000155504">Toko 081000155504</a-select-option>
            <a-select-option value="toko_081000155503">Toko 081000155503</a-select-option>
            <a-select-option value="toko_081000155502">Toko 081000155504</a-select-option>
            <a-select-option value="toko_081000155501">toko 081000155504</a-select-option>
             <a-select-option value="toko_test_ajjajaja_edit">Toko Test Ajjajaja Edit</a-select-option>
             <a-select-option value=""></a-select-option>
              <a-select-option value=""></a-select-option>
              <a-select-option value=""></a-select-option>
              <a-select-option value=""></a-select-option>
              <a-select-option value=""></a-select-option>
              <a-select-option value=""></a-select-option>
              <a-select-option value=""></a-select-option>
            </a-select>
          </div>
          <div class="card-body mt-2 ml-2">
             <filter-products v-model:value="products" v-model:brand="products" />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder">
          <div class="card-body mt-2 ml-2">
            <filter-area v-model:value="areas" v-model:province="province" />
          </div>
          <div class="card-body mt-2 ml-2">
             <a-date-picker v-model:value="value2" :format="dateFormatList" placeholder="Pilih Tanggal Akhir" />
          </div>
          <div class="card-body mt-2 ml-2">
              <a-select label-in-value v-model:value="value" style="width: 300px" @change="handleChange" placeholder="Pilih Nama Loyalti">
            <a-select-option value="loyal">Loyal</a-select-option>
            <a-select-option value="loyalti_akhir_tahun">Loyalti Akhir Tahun</a-select-option>
            <a-select-option value="poin_loyalti_region_3q2">Poin Loyalti Region 3 Q2</a-select-option>
            <a-select-option value="poin_oktober">Poin Oktober</a-select-option>
             <a-select-option value="tes_301">Tes 301</a-select-option>
             <a-select-option value="poin_oktober_ll">Poin Oktober ll</a-select-option>
              <a-select-option value="loyalty_test">Loyalty Test</a-select-option>
              <a-select-option value="poin_loyalti_region_3q1">Poin Loyalti Region 3 Q1</a-select-option>
              <a-select-option value=""></a-select-option>
              <a-select-option value=""></a-select-option>
              <a-select-option value=""></a-select-option>
              <a-select-option value=""></a-select-option>
            </a-select>
          </div>
        </div>
        <div class="card-placeholder">
          <div class="card-body mt-2 ml-2" style="width: 300px;">
            <a-button type="primary">Terapkan</a-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-header mt-3 ml-2">
            <a-button type="primary" title="Tambah Loyalti" class="ml-2 mr-2" style="background-color: #606661; border: none; color: white;"
              @click="
                ()=> {
                  formState = {}
                  formState.status_approval = 0
                  formState.type = 1
                  setModal1Visible(true)
                  formState.title_state = 'Tambah Loyalti'
                  formState.state = 'new'
                  formState.readOnly = false
                }
              "
                  >

                <i class="fa fa-plus" aria-hidden="true"></i>
              </a-button>

             <a-button
                type="primary"
                stylea="width: 200px; background-color: #3acf41; border: none;"
                title="Download Xls"
                @click="fetchXlsx"
              >
                <i class="fa fa-file-excel-o" aria-hidden="true"></i>
              </a-button>

          </div>
        </div>
      </div>
    </div>
  </div>
         <a-modal
          v-model:visible="modal1Visible"
            title="Tambah Loyalti Baru"
            width="1000px"
            @ok="setModal1Visible(false)"
              >
            <template #footer>
            <a-button key="submit" type="primary" :loading="loading" @click="submit">Simpan</a-button>
            </template>

          <a-form :wrapper-col="{ span: 14 }" class="myform">
              <a-row class="form-row">
                <a-col :md="12" :sm="24">
                  <a-form-item
                    label="Tanggal Mulai"
                    label-align="left"
                    :label-col="{ sm: { span: 8 } }"
                    :wrapper-col="{ sm: { span: 24 - 8 } }"
                  >
                    <a-date-picker
                      placeholder="Pilih Tanggal Mulai"
                      v-model:value="value2"
                      :format="dateFormatList"
                      required
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-form-item
                    label="Tanggal Akhir"
                    label-align="left"
                    :label-col="{ sm: { span: 8 } }"
                    :wrapper-col="{ sm: { span: 24 - 8 } }"
                  >
                    <a-date-picker
                      placeholder="Pilih Tanggal Akhir"
                      v-model:value="value2"
                      :format="dateFormatList"
                      required
                    />
                  </a-form-item>
                </a-col>
              </a-row>


            <a-row class="form-row mb-2">
              <a-col :sm="24">
                <a-form-item
                  label="Nama Loyalti"
                  label-align="left"
                  :label-col="{ sm: { span: 4 } }"
                  :wrapper-col="{ sm: { span: 24 - 4 } }"
                  required
                >
                  <a-input
                    placeholder="Nama Loyalti"
                    v-model:value="formState.address"
                    :disabled="formState.readOnly"
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row class="form-row mb-4">
              <a-col :sm="24">
                <a-form-item
                  label="Regional"
                  label-align="left"
                  :label-col="{ sm: { span: 4 } }"
                  :wrapper-col="{ sm: { span: 24 - 4 } }"
                  required
                  style="margin-top: -5px"
                >
                  <filter-regional
                  v-model:value="formState.regionals"
                  label="fullname"
                  :disabled="formState.readOnly"
                  style="width: 100%"
                  >
                  </filter-regional>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row class="form-row">
              <a-col :sm="24">
                <a-form-item
                  label="Provinsi"
                  label-align="left"
                  :label-col="{ sm: { span: 4 } }"
                  :wrapper-col="{ sm: { span: 24 - 4 } }"
                  style="margin-top: -20px"
                  required
                >
                  <filter-province
                  v-model:value="formState.province"
                  label="fullname"
                  :disabled="formState.readOnly"
                  style="width: 100%"
                  >
                  </filter-province>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row class="form-row">
              <a-col :sm="24">
                <a-form-item
                  label="Area"
                  label-align="left"
                  :label-col="{ sm: { span: 4 } }"
                  :wrapper-col="{ sm: { span: 24 - 4 } }"

                  required
                >
                  <filter-area
                  v-model:value="formState.areas"
                  label="fullname"
                  :disabled="formState.readOnly"
                  style="width: 100%"
                  >
                  </filter-area>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row class="form-row">
              <a-col :sm="24">
                <a-form-item
                  label="Kabupaten / Kota"
                  label-align="left"
                  :label-col="{ sm: { span: 4 } }"
                  :wrapper-col="{ sm: { span: 24 - 4 } }"

                  required
                >
                  <filter-kabupaten-kota
                  v-model:value="formState.kota"
                  label="fullname"
                  :disabled="formState.readOnly"
                  style="width: 100%"
                  >
                  </filter-kabupaten-kota>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row class="form-row">
              <a-col :sm="24">
                <a-form-item
                  label="Distributor"
                  label-align="left"
                  :label-col="{ sm: { span: 4 } }"
                  :wrapper-col="{ sm: { span: 24 - 4 } }"

                  required
                >
                  <filter-distributor
                  v-model:value="formState.kota"
                  label="fullname"
                  :disabled="formState.readOnly"
                  style="width: 100%"
                  >
                  </filter-distributor>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row class="form-row">
              <a-col :sm="24">
                <a-form-item
                  label="ID BK"
                  label-align="left"
                  :label-col="{ sm: { span: 4 } }"
                  :wrapper-col="{ sm: { span: 24 - 4 } }"

                  required
                >
                  <a-select  style="width: 100%" @change="handleChange" placeholder="Pilih ID BK ">
                    <a-select-option value=""></a-select-option>
                    <a-select-option value=""></a-select-option>
                    </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row class="form-row">
              <a-col :sm="24">
                <a-form-item
                  label="Nama Toko"
                  label-align="left"
                  :label-col="{ sm: { span: 4 } }"
                  :wrapper-col="{ sm: { span: 24 - 4 } }"

                  required
                >
                  <a-select  style="width: 100%" @change="handleChange" placeholder="Pilih Nama Toko ">
                    <a-select-option value=""></a-select-option>
                    <a-select-option value=""></a-select-option>
                    </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row class="form-row">
              <a-col :sm="24">
                <a-form-item
                  label="Brands"
                  label-align="left"
                  :label-col="{ sm: { span: 4 } }"
                  :wrapper-col="{ sm: { span: 24 - 4 } }"

                  required
                >
                  <filter-brands
                  v-model:value="formState.brands"
                  label="fullname"
                  :disabled="formState.readOnly"
                  style="width: 100%"
                  >
                  </filter-brands>
                </a-form-item>
              </a-col>
            </a-row>

          </a-form>
         </a-modal>
</template>

<script>
import moment, { Moment } from 'moment';
import { ref, watch, onMounted, reactive, provide, toRefs } from 'vue';
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterProducts from '@/components/filter/FilterProduct'
import FilterBrands from '@/components/filter/FilterBrand'
export default ({
 components: {
    // MModal,
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterDistributor,
    FilterProducts,
    FilterBrands,
  },

  setup() {
    const modal1Visible = ref(false);
    const dateFormat = 'YYYY/MM/DD';
    const monthFormat = 'YYYY/MM';
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    const setModal1Visible = visible => {
      modal1Visible.value = visible;
    };
    return {
      value2: ref<Moment>(moment('01/01/2015', dateFormatList[0])),
      dateFormat,
      monthFormat,
      dateFormatList,
      moment,
      modal1Visible,
      setModal1Visible,
    };
  },
});
</script>

<style scoped>
.myform :deep(.ant-input-disabled),
.myform :deep(.ant-input[disabled]),
.myform
  :deep(.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  /* color: #595c97 !important; */
  color: black;
  background-color: white;
}
.myform :deep(.ant-form-item) {
  margin-bottom: initial;
}
</style>
